import type { TWidgetProps, TWidgetSetUpProps } from '#Apps/Widget/Widget.types.ts';
import type { TCommodity } from '#types/WorkDigitalBaseTypes.ts';

export const parsePropsWidget = (props: TWidgetProps) => {
  let {
    widget,
    campaign,
    bcampaign: bCampaign,
    business,
    defaultcommodity: defaultCommodity,
    skipredirect: skipRedirect
  } = props;

  const {
    redirect,
    showtypeswitchtabs: showTypeSwitchTabs,
    uvpcodeparam: uvpCodeParam,
    vpcodeparam: vpCodeParam,
    zipcodeparam: zipCodeParam,
    usageparam: usageParam,
    redirectwithurlparam: redirectWithUrlParam
  } = props;
  if (!widget) {
    widget = import.meta.env.VITE_APP_DEFAULT_WIDGET;
  }
  if (!campaign) {
    campaign = import.meta.env.VITE_APP_DEFAULT_CAMPAIGN ? import.meta.env.VITE_APP_DEFAULT_CAMPAIGN : 'default';
  }
  let withoutBCampaign = false;
  if (!bCampaign) {
    withoutBCampaign = true;
    bCampaign = campaign;
  }
  if (typeof business === 'undefined') {
    business = parseInt(import.meta.env.VITE_APP_DEFAULT_BUSINESS);
  }
  if (!defaultCommodity) {
    defaultCommodity = import.meta.env.VITE_APP_DEFAULT_COMMODITY ?? 'electricity';
  }
  if (!skipRedirect) {
    skipRedirect = import.meta.env.VITE_APP_SKIP_REDIRECT === 'true';
  }
  const consumptionType = 'household_electricity';
  const priceType = 'single_price';

  if (!widget) {
    throw new Error('No widget provided');
  }
  if (!campaign) {
    throw new Error('No campaign provided');
  }
  if (!business && business !== 0) {
    throw new Error('No business provided');
  }
  if (!defaultCommodity) {
    throw new Error('No defaultCommodity provided');
  }

  const setupProps: TWidgetSetUpProps = {
    widget,
    campaign,
    bCampaign,
    withoutBCampaign,
    business,
    commodity: defaultCommodity as TCommodity,
    consumptionType,
    priceType,
    redirect,
    showTypeSwitchTabs: showTypeSwitchTabs ?? false,
    skipRedirect,
    redirectWithUrlParam,
    urlParams: {
      uvp: uvpCodeParam ?? 'uvp-code',
      vp: vpCodeParam ?? 'vp-code',
      zip: zipCodeParam ?? 'zip',
      usage: usageParam ?? 'usage'
    }
  };

  return setupProps;
};
