import type { TFormErrors } from '@workdigital/component-library-react';
import type { AxiosResponse } from 'axios';

export function orderObjectByArrayKeys<TObject extends Record<string, unknown>>(
  obj: TObject,
  order: Array<keyof TObject>
): TObject {
  const ordered = {} as TObject;
  order.forEach((key) => {
    const isKeyInObject = key in obj;
    const keyNotExist = !isKeyInObject && import.meta.env.DEV;

    if (isKeyInObject) {
      ordered[key] = obj[key];
    }

    if (keyNotExist) {
      console.warn("A key was provided that doesn't exist in the object");
    }
  });
  return ordered;
}

export function commoditiesObjectToArray<TObject extends Record<string, unknown>>(obj: TObject): string[] {
  return Object.keys(obj).filter((key) => obj[key] == true) as string[];
}

export function convertErrorsFromResponse(response: AxiosResponse): TFormErrors {
  const errorsObj: TFormErrors = {};
  Object.keys(response.data.error.messages).forEach((key) => {
    // @todo need to figure out do we need all three phone types or we can use just one
    if (key === 'phoneMobile') {
      errorsObj['phone'] = [response.data.error?.messages[key][0].message];
      return;
    }
    errorsObj[key] = [response.data.error?.messages[key][0].message];
  });
  return errorsObj;
}

export function safeStringify<TInput = object, TOutput = object>(
  value: TInput,
  replacer?: (key: string, value: TInput) => TOutput,
  space?: string | number
): string | null {
  let result: string | null = null;
  try {
    result = JSON.stringify(value, replacer, space);
  } catch (error) {
    console.error('Error stringifying JSON:', error);
    // Handle the error as needed, for example, return a default string or null
    result = null;
  }
  return result;
}
